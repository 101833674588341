.pumahara-logo {
  background: url('../../assets/img/pumahara-logo-64x64.webp') no-repeat;
  min-height: 64px;
  min-width: 64px;
}

.icon-text {
  margin-left: 1.25rem;
  position: relative;
  top: -1rem;
}

.top-35pc {
  position: absolute;
  top: 35%;
}
